#player-highlights-page-container {
  min-height: 100vh;
  width: 100vw;
  position: relative;
  background-color: black;

  & #navbar-container {
    position: sticky;
    top: 0;
    z-index: 200;
  }

  & #video-content-container {
    height: fit-content;
    width: fit-content;

    #video-heading-count {
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: black;
      background-color: white;
      width: 60vw;
      position: relative;
      left: 20vw;
      top: 5vh;
      border-radius: 5px 5px 0 0;

      & h1 {
        display: inline-block;
        margin-left: 1vw;
      }

      & #video-count {
        display: inline-block;
        margin-right: 1vw;
        font-size: 0.6vw;
        color: red;
        font-weight: 600;
      }
    }
  }

  #no-search-results {
    position: relative;
    width: fit-content;
    color: red;
    display: none;
    font-size: 1.2vw;
    background-color: white;
    top: 25vh;
    left: 25vw;
  }

  & #filters-container {
    width: 55vw;
    margin-left: 20vw;
    display: flex;
    align-items: center;

    & #video-filters {
      visibility: hidden;
      display: flex;
      justify-content: space-evenly;
      width: 55vw;
      height: fit-content;
      margin-bottom: -3vh;

      & .filter-dropdown {
        position: relative;
        top: 5vh;
        text-align: center;
        background-color: lightgray;
        font-weight: 500;
        font-style: italic;
        color: black;
        width: 7vw;
        height: 2.5vh;
      }
    }

    & #clear {
      background-color: #3b71ca;
      border: none;
      color: white;
      font-weight: 600;
      margin-bottom: -13vh;
      width: 7vw;
      height: 2.5vh;
      cursor: pointer;
      visibility: hidden;
    }
  }

  & svg {
    position: relative;
    top: 5.25vh;
    left: 75vw;
    height: 2.5vh;
    width: 5vw;
    color: #3b71ca;
    z-index: 100;
    cursor: pointer;
  }

  #dropdown-basic-button {
    background-color: #3b71ca;
    border: none;
    position: relative;
    left: 81vw;
    top: 3.1vh;
    font-size: 0.5vw;
    font-weight: 600;
  }

  & .background-img-sides {
    margin-top: 5vh;
    width: 20vw;
    min-height: 100vh;
    position: absolute;
  }

  & .background-img-left {
    & #image-left {
      position: fixed;
      top: 40vh;
      width: 20vw;
      margin-left: 3vw;
      width: 12vw;
      height: 22vh;
      filter: grayscale(100%);
      border-radius: 25px;
    }
  }

  & .background-img-right {
    right: 0;

    & #image-right {
      position: fixed;
      top: 40vh;
      width: 20vw;
      margin-left: 5vw;
      width: 10vw;
      height: 20vh;
      filter: sepia(100%);
      filter: brightness(120%);
      border-radius: 25px;
    }
  }

  & .video-modal {
    background-color: white;
    width: 70vw;
    height: 73vh;
    position: absolute;
    left: 15vw;
    z-index: 100;
    border: 3px solid orangered;

    & div:first-child {
      margin-top: 3vh;
      width: 70vw;
      height: 70vh;
    }

    & #close-icon {
      position: absolute;
      right: 0.75vw;
      top: -0.35vh;
      font-size: 1.5vw;
    }

    & #close-icon:hover {
      cursor: pointer;
      color: red;
      transform: scale(1.15);
    }
  }

  & #video-highlight-thumbnails {
    background-color: white;
    color: black;
    width: 60vw;
    height: fit-content;
    position: relative;
    left: 20vw;
    border-radius: 0 0 5px 5px;

    & #video-container {
      margin-top: 5vh;
      margin-left: 0.25vw;
      margin-right: 0.25vw;
      width: 59.5vw;
      border: 0.5px solid lightgray;
      display: flex;

      & img {
        padding-top: 0.25vh;
        padding-bottom: 0.25vh;
        width: 25vw;
        height: 25vh;
        border-radius: 15px;
        object-fit: none;
      }

      & #video-attributes {
        margin-left: 0.5vw;
        display: flex;
        flex-direction: column;
        justify-content: center;

        & #video-title {
          font-size: 0.75vw;
          margin-bottom: 0.5vw;
          margin-right: 2vw;
        }
        & > span:not(:first-of-type) {
          margin-top: 0.25vw;
          font-size: 0.5vw;
          font-weight: 500;
          font-style: italic;
        }
      }
    }

    #video-container > img:hover {
      cursor: pointer;
    }
  }

  & #extra-space {
    height: 5vh;
  }

  & .hidden {
    display: none;
  }

  & .fade {
    opacity: 0.2;
  }

  & .youtube-video-container {
    color: white;
  }
}
