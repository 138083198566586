#scores-page-container {
  background-color: var(--color-lightGray);
}

#dropdowns-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 25vw;
  height: 5vh;
  font-size: 0.6vw;
  font-weight: 600;
  background-color: rgb(214, 217, 219);
  border-radius: 10px;
}

.single-dropdown-container {
  display: flex;
  flex-direction: column;
}

.scores-headers-container {
  display: flex;
  justify-content: space-between;
}

#scoreboard-container {
  display: flex;
  flex-direction: column;
  height: fit-content;
  width: 50vw;
  margin-left: 25vw;
  margin-top: 5vh;
  padding: 1vh 1vw;
  background-color: white;
  border-radius: 25px;
}

#individual-game-container {
  border: 0.6px solid gray;
  height: fit-content;
  position: relative;
}

#individual-game-container:hover {
  background-image: linear-gradient(
      to right bottom,
      rgba(0, 0, 0, 0.8),
      rgba(0, 0, 0, 0.4)
    ),
    url('https://media.jlgarchitects.com/jlgice-blobstorage/2020/02/CC-Robson-Arena_bowl_200218.jpg');
  background-size: cover;
  background-position: 25%;
  color: white;
}

#individual-game-container:hover a {
  color: white;
  font-weight: 700;
}

#individual-game-container:hover .scores-button-container > a {
  color: white;
  font-weight: 700;
  border: 2.5px solid white;
}

#individual-game-container:hover .player-stats-info {
  font-weight: 600;
}

#individual-game-container > #game-status {
  margin-left: 0.5vw;
  margin-bottom: 2vh;
}

.team-container {
  display: flex;
  font-size: 0.7vw;
  margin: -0.5vh 1vw;
  height: 10vh;
}

.scoreboard-team-info {
  display: flex;
  flex-direction: column;
}

.scoreboard-team-info a {
  color: black;
}

.scoreboard-team-name-logo {
  display: flex;
  /* align-items: center; */
  width: 15vw;
  text-decoration: none;
}

.scoreboard-team-logo {
  width: 3vw;
  height: 3vw;
}

.scoreboard-team-name {
  margin-left: 0.75vw;
}

.scoreboard-team-name:hover {
  scale: 1.07;
}

.scoreboard-record {
  font-size: 0.6vw;
  position: relative;
  left: 3.75vw;
  bottom: 4.25vh;
}

.venue-gameTime-container {
  font-size: 0.6vw;
  font-style: italic;
  position: relative;
  height: fit-content;
  width: 25vw;
  bottom: 5.5vh;
  left: -3vw;
  text-align: center;
}

.scores-button-container {
  position: absolute;
  left: 13vw;
  top: 15vh;
  width: 25vw;
  display: flex;
  justify-content: space-evenly;
}

.scores-button-container > a {
  text-decoration: none;
  font-weight: 400;
  width: 7vw;
  height: 3vh;
  border: 1px solid blue;
  color: blue;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.scores-button-container > a:hover {
  background-color: rgb(56, 56, 56);
  color: white;
}

.venue-gameTime-container #game-time {
  font-size: 0.5vw;
  margin-top: -1vh;
}

.scoreboard-scores-container {
  font-size: 0.8vw;
  font-weight: 600;
  position: relative;
  right: 4vw;
}

.scoreboard-top-players-container {
  display: flex;
  flex-direction: column;
  width: 12vw;
  position: absolute;
  top: 5.2%;
  left: 80%;
}

.scoreboard-top-player {
  display: flex;
  margin: 0.75vh 0 0.75vh 0;
}

#scoreboard-container .player-profile-pic {
  width: 2.5vw;
  height: 5vh;
  border: 2px solid orangered;
}

.player-profile-pic:hover {
  scale: 1.07;
}

#no-games-message {
  margin-top: 5vh;
  margin-left: 1vw;
  font-style: italic;
  color: red;
}

/*? ------------------------------------------------------- Media Queries ---------------------------------------------------------- */
@media only screen and (min-device-width: 768px) and (max-device-width: 1194px) and (-webkit-min-device-pixel-ratio: 1) {
  #scoreboard-container {
    width: 60vw;
    margin-left: 20vw;
    padding: 1vh 2vw;
  }

  #individual-game-container {
    border: 2px solid gray;
    height: 40vh;
    width: 60vw;
    position: relative;
  }

  .team-container {
    font-size: 1vw;
    margin: -0.5vh 1vw;
  }

  .team-container:nth-of-type(2) {
    margin-top: 3vh;
  }

  .scoreboard-record {
    font-size: 0.8vw;
    position: relative;
    left: 4vw;
    bottom: 3vh;
  }

  #scoreboard-container .player-profile-pic {
    width: 2.75vw;
    height: 4vh;
    border: 2px solid orangered;
  }
}
