#navbar-container {
  height: 6vh;
  display: flex;
  align-items: center;
  background-color: black;
}

#navbar-content {
  width: 100vw;
  height: 6vh;
  display: flex;
  justify-content: space-around;
  align-items: center;

  & #site-logo {
    color: red;
    font-weight: 800;
    font-style: italic;
  }
}

#navbar-content a {
  color: white;
  font-size: 1.3vh;
  font-weight: 600;
  text-decoration: none;
}

.hover-content {
  display: none;
  position: relative;
  top: 0.75vh;
}

.hover-content > a {
  display: block;
  margin-top: 1vh;
  margin-left: 1vw;
}

.drop-down:hover .hover-content {
  display: block;
  margin-top: 3vh;
  position: absolute;
  width: 8vw;
  height: 20vh;
  background-color: black;
  border-radius: 10px;
  z-index: 100;
}
