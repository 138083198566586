@import '../headlineGame.css';

.hockey-home-container {
  width: 100vw;

  & .hockey-home-content-container {
    width: 90vw;
    margin-left: 5vw;
    margin-top: 1vh;
    display: flex;
    justify-content: center;

    & .hockey-home-gameCard-container {
      margin-right: 1vw;
    }

    & .headline-game-container {
      height: fit-content;
      width: fit-content;

      & .hockey-home-headlineGame-container {
        width: fit-content;
      }
    }

    & .hockey-home-topHeadlines-container {
      background-color: var(--color-whiteBackground);
      border-radius: 8px;
      margin-left: 1vw;
      box-shadow: 5px 5px 15px 2px var(--color-grayText);
      line-height: 1.5vh;
      height: fit-content;
      width: fit-content;
      margin-top: 1vh;

      & .topHeadlines-container {
        background-color: var(--color-whiteBackground);
        border-radius: 8px;
        margin-right: 0.6vw;
        margin-left: 0.5vw;
        margin-bottom: 2vh;
        width: fit-content;
      }
    }
  }
}
