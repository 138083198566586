@import url('https://fonts.googleapis.com/css2?family=Sintony&display=swap'); /* Sintony font-family import from Google fonts */

.teams-container {
  height: fit-content;
}

.teams-container h1 {
  font-size: 1.2vw;
  font-weight: 800;
  position: relative;
  left: 25vw;
  text-align: center;
  width: 50vw;
  margin-bottom: 5vh;
}

#team-logo {
  display: inline-block;
  height: 10vh;
  width: 7vw;
  position: relative;
  left: 46.4vw;
}

.pill-container {
  display: flex;
  justify-content: center;
}

.pill {
  margin-left: 5vw;
  border: 4px solid orangered;
  border-radius: 5px;
  padding: 0.5vh 0.5vw;
  font-size: 1.2vw;
  text-decoration: none;
  background-color: black;
  color: white;
}

.pill:hover {
  cursor: pointer;
}

.teams-container h3 {
  font-size: 0.8vw;
  font-weight: 800;
  position: relative;
  left: 25vw;
  text-align: center;
  width: 50vw;
}

.teams-container .filter-dropdowns-container {
  display: flex;
  background-color: var(--color-lightGray);
  width: 40vw;
  height: 5vh;
  border-radius: 5px;
}

.teams-container .filter-dropdowns {
  display: flex;
  flex-direction: column;
  margin-left: 1vw;
}

.teams-container label {
  font-size: 0.6vw;
  margin-top: 0.25vh;
  margin-bottom: 0.5vh;
}

.teams-container select {
  position: relative;
  margin-right: 2vw;
  margin-bottom: 3vw;
  width: 7vw;
  font-size: 0.55vw;
}

.teams-container select option {
  position: absolute;
  top: 50%;
}

.roster-table {
  width: 50vw;
  position: relative;
  left: 25vw;
  background-color: var(--color-whiteBackground);
  border-spacing: 0;
  margin-bottom: 7.5vh;
  border-radius: 10px;
}

.roster-table tr > th {
  background-color: black;
  color: white;
  line-height: 3vh;
  font-size: 0.6vw;
}

.shaded {
  width: 20vw;
}

.roster-table-data td {
  font-size: 0.6vw;
  font-family: 'Sintony', sans-serif;
  text-align: center;
  line-height: 0vh;
  border-bottom: 0.5px solid var(--color-grayText);
}

.roster-table-data .left {
  text-align: left;
}

/* Styling for the shaded area on table which includes the profile pic and player name */
.roster-table-data a {
  text-decoration: none;
  color: black;
  background-color: var(--color-lightGray);
  padding: 1vh 0.2vw;
}

.roster-table-data a > p:hover {
  scale: 1.07;
}

/* Styling for the profile pic */
.player-profile-pic {
  width: 2.8vw;
  height: 5vh;
  border-radius: 50%;
  margin-right: 0.5vw;
}

/* Hover over player image */
.roster-table .player-profile-pic:hover {
  scale: 1.075;
}

.teams-container h3 {
  color: orangered;
}

.player-img-name {
  display: flex;
  align-items: center;
}
