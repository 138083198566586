.video-container {
  width: fit-content;

  & h1 {
    font-size: 0.75vw;
  }

  & .outer-container {
    margin-bottom: 0.5vh;
    width: 34.25vw;

    & .video-card {
      width: 34.25vw;
      padding: 3vh 1vw 3vh 1vw;
      border: 1px solid var(--color-lightGray);
      border-radius: 25px;
      background-color: var(--color-whiteBackground);

      & .video-content {
        border-radius: 10px;
        overflow: hidden;
        width: 32vw;

        & iframe {
          width: 32vw;
          height: 35vh;
        }
      }
    }
  }
}
