.hockey-home-topHeadlines-container {
  & .topHeadlines-container {
    width: 23vw;
    height: fit-content;
    background-color: var(--color-whiteBackground);
    border-radius: 8px;
    margin-bottom: 2vh;
  }

  & .topHeadlines-container > h1 {
    padding-top: 1vh;
    padding-left: 1vw;
    font-size: 0.8vw;
    font-weight: 400;
  }

  & .topHeadlines {
    padding-left: 1vw;
    margin-top: 0.525vh;
  }

  & .topHeadlines > li > a {
    font-size: 0.65vw;
    text-decoration: none;
    color: var(--color-greyText);
    font-weight: 300;
  }

  & .topHeadlines > li {
    list-style: disc;
  }

  & li::marker {
    font-size: 0.8vw;
  }
}
