#standings-page-container {
  width: 100vw;
  height: 105vh;
  position: relative;
  background-color: lightgray;
}

.center-items {
  width: 62vw;
  height: fit-content;
  margin-top: 2.5%;
  margin-left: 18.5%;
  background-color: white;
  border-radius: 25px;
  box-shadow: 1px 3px 3px 3px rgba(0, 0, 0, 0.4);
}

#standings-page-container #dropdowns-container {
  background-color: white;
  width: 50%;
  margin-left: 25%;
  margin-right: 25%;
}

.single-dropdown-container label {
  text-align: center;
}

.page-title {
  position: relative;
  left: 45%;
  margin-top: 3vh;
  margin-bottom: 0.75vh;
}

#standings-container {
  height: fit-content;
  display: inline-block;
  width: 62vw;
  margin-left: 1vw;
  margin-right: 1vw;
}

#standings-team-logo {
  width: 8vw;
  border-right: 0.5px solid lightgray;
}

#standings-container #standings-team-logo > a {
  text-decoration: none;
  display: flex;
  align-items: center;
  margin-left: 0.25vw;
}

#standings-container #standings-team-logo > a > p:hover {
  transform: scale(1.05);
}

#standings-team-logo img {
  width: 3vw;
  height: 5vh;
  margin-right: 0.75vw;
}

#standings-container table {
  height: fit-content;
  width: 60vw;
  background-color: white;
  border: 1px solid lightgray;
  border-collapse: collapse;
  margin-bottom: 5vh;
}

#standings-container thead {
  background-color: black;
  color: white;
  text-align: center;
}

#standings-container table td {
  border-top: 1px solid lightgray;
  border-bottom: 1px solid lightgray;
  line-height: 3;
  font-size: 0.55vw;
  color: black;
  text-align: center;
  vertical-align: middle;
}

#standings-container th {
  font-size: 0.5vw;
  font-weight: 600;
  background-color: black;
  color: white;
  height: 3vh;
}

#stat-points {
  background-color: rgb(229, 234, 249);
}
